<template>
	<div class="enterprise organizationBox">
		<div v-show="visit" class="tips_top_box">
			<p class="tips_titele_box"><span class="tips_title">使用指南</span> <span class="close_open"
					@click='visit=false'>关闭提示</span></p>
			<p> 1. 如果职级需要分组，请在职级通道管理中开启分组。</p>
			<p>2. 对职级职等还不熟悉可以点击 了解职级体系</p>
		</div>
		<div class="passages_170xn">
			<span @click='choose_channel(item)'
				:class="item.id==active_channel?'passage_19z-n active_1NcT-':'passage_19z-n'"
				v-for="item in rank_channel_list">
				{{item.name}}
			</span>

			<span class="settings_1_vPz" @click='sortModel=true'>
				<a-icon type="robot" style="font-size: 20px;" />
				<span>
					管理职级通道
				</span>
				<a-icon type="right" />
			</span>
		</div>
		<div class="organizationHeader">
			<div class="o_h_left">
				<a-input allow-clear v-model:value="where.keyword" placeholder="职级名称/职级编码" @change="get_rank_List">
					<template #prefix>
						<a-icon type="search" style="color: #999999" />
					</template>
				</a-input>
			</div>
			<div class="o_h_right">
				<span v-if='!visit' class='cq_color' @click='visit=true'>
					<TagsOutlined />使用指南
				</span>
				<a-button type="primary" @click="addSubordinate">添加职级</a-button>
				<a-button class='pointerCancel' v-if='active_channel_info.is_group==1'
					@click='addGroupModel=true'>添加职级分组</a-button>
				<a-dropdown>
					<a-button class='pointerCancel'>更多操作
						<a-icon type="down" />
					</a-button>
					<template #overlay>
						<a-menu>
							<a-menu-item @click="down_link">
								<span class='uploadLine pointerCancel'>
									导出职级明细表
								</span>
							</a-menu-item>
							<!-- 
							<a-menu-item @click="historyOrganization">
								导出职级体系地图
							</a-menu-item>
 -->
						</a-menu>
					</template>
				</a-dropdown>
			</div>
		</div>
		<div class="organizationContain">
			<a-table rowKey='id' :dataSource="rank_list" :columns="columns" :defaultExpandAllRows="true">
				<template slot='op' slot-scope="text,record,index">
					<div class='op' v-if='record.id !=0'>
						<span class='cq_color ' @click="setSubordinate(record)">编辑</span>
						<span class='cq_color line'   v-if='record.is_group'
							@click="setbatch(record)">批量添加职级  </span>
						<span class='cq_error' v-if='record.level && record.user_num==0'
							@click="derankOk(record.id)"> 删除</span> 
					</div>
				</template>
			</a-table>
		</div>
		<!-- 新增组织 -->
		<a-modal title="新增职级" @ok='add_rank' :maskClosable="true" :visible="visibleDrawer" @cancel="addOnClose"
			width="800px">
			<a-form :labelCol="labelCol" :wrapperCol="wrapperCol" :model="addformState" autocomplete="off"
				ref="addForm">
				<a-form-item required class='mb10' :labelCol='{span:4}' :wrapperCol="{span:15}" label="职级通道" name="channel_id"
					:rules="[{ required: true, message: '通道不能为空' }]">
					<a-select v-model:value="addformState.channel_id" @change="changechannel">
						<a-select-option :value="it.id" v-for='(it,index) in rank_channel_list' :key='index'>{{it.name}}</a-select-option>
					</a-select>
				</a-form-item>
			
				<a-form-item required class='mb10' :labelCol='{span:4}' :wrapperCol="{span:15}" label="职级编号" name="code"
					:rules="[{ required: true, message: '编号不能为空' }]">
					<a-input placeholder="请输入" v-model:value="addformState.code"></a-input>
				</a-form-item>
				<a-form-item  class='mb10' :labelCol='{span:4}' :wrapperCol="{span:15}" label="职级名称" name="name">
					<a-input placeholder="请输入" v-model:value="addformState.name"></a-input>
				</a-form-item>
				<a-form-item  class='mb10' :labelCol='{span:4}' :wrapperCol="{span:15}" name="superior"
					v-if='active_channel_info.is_group==1 ||is_group==1' label="职级分组">
					<a-select style='width:90%' placeholder="请选择" v-model:value="addformState.group_id">
						<a-select-option :value="it.id" v-for='(it,index) in group_list' :key='index'>{{it.name}}</a-select-option>
					</a-select>
					<PlusCircleOutlined style='margin-left: 10px;' @click='addGroupModel=true' />
				</a-form-item>
				<a-form-item required class='mb10' style='margin-bottom:30px' :labelCol='{span:4}' :wrapperCol="{span:15}"
					name="function" label="关联等级" :rules="[{ required: true, message: '职等不能为空' }]">
					<div class="job_lv_qQOHK">
						<div class="boxs_3Z7My">
							<span :class="addformState.level==item?'selected_3r1hy':'' "
								:style="{background:use_level.indexOf(String(item))>-1?'#e2e2e2':''}"
								@click='choose_level(item)' v-for="item in 100">
									{{item}}
							</span>
						</div>
						<p class="msg_33-HC">职等数字越大，表示职级的级别越高</p>
					</div>
				</a-form-item>
				<a-form-item class='mb10' :labelCol='{span:4}' :wrapperCol="{span:15}" name="lead" label="描述">
					<a-textarea v-model:value="addformState.describe" placeholder="请输入描述" allow-clear />
				</a-form-item>
			</a-form>
		</a-modal>
		<!--批量新增职级 -->
		<a-modal title="批量新增职级" @ok='add_batch' :maskClosable="true" :visible="visiblebatch" @cancel="batchClose"
			width="800px">
			<a-form :label-col="labelCol" :wrapper-col="wrapperCol" :model="batchinfo" autocomplete="off" ref="addForm">
				<a-form-item class='mb10' :labelCol='{span:6}' :wrapperCol="{span:15}" label="职级编号" name="code">
					{{batchinfo.code?batchinfo.code:'暂无'}}
				</a-form-item>

				<a-form-item class='mb10' :labelCol='{span:6}' :wrapperCol="{span:15}" name="superior" label="职级分组名称">
					{{batchinfo.name}}
				</a-form-item>
				<a-form-item class='mb10' :labelCol='{span:6}' :wrapperCol="{span:15}" name="function" label="关联等级"
					:rules="[{ required: true, message: '职等不能为空' }]">
					<div class="job_lv_qQOHK">
						<div class="boxs_3Z7My">
							<span
								:class="batchinfo.level.indexOf(item)>-1 ?'selected_4r1hy':levelList.indexOf(item)>-1?'selected_3r1hy':'' "
								:style="{background:use_level.indexOf(String(item))>-1?'#e2e2e2':''}"
								@click='choose_level2(item)' v-for="(item,index) in 100" :key='index'>
								{{item}}

							</span>
						</div>
						<p class="msg_33-HC">职等数字越大，表示职级的级别越高</p>
					</div>
				</a-form-item>
				<a-form-item class='mb10' :labelCol='{span:6}' :wrapperCol="{span:15}" v-if='levelList.length>0'
					name="superior" label="自动生成职级编号">
					<a-tag v-for='(it,index) in levelList' :key='index'>
						{{batchinfo.code}}-{{it}}
					</a-tag>
				</a-form-item>

			</a-form>
		</a-modal>
		<a-modal @cancel='cancelGroup' v-model:visible="addGroupModel" title="新增职级分组" @ok="handleOkgroup">
			<a-form :model="addgroup" :label-col="{span:5}" :wrapper-col="{span:18}">
				<a-form-item required class='mb10' :labelCol='{span:6}' :wrapperCol="{span:15}" name="code"
					:rules="[{ required: true, message: '职级分组编号不能为空',tagger:'blur' }]" label="职级分组编号">
					<a-input placeholder="请输入" v-model:value="addgroup.code"></a-input>
				</a-form-item>
				<a-form-item required class='mb10' :labelCol='{span:6}' :wrapperCol="{span:15}"
					v-if='active_channel_info.is_group==1' name="name" label="职级分组名称">
					<a-input placeholder="请输入" v-model:value="addgroup.name"></a-input>
				</a-form-item>
				<a-form-item class='mb10' :labelCol='{span:6}' :wrapperCol="{span:15}" name="lead" label="描述">
					<a-textarea v-model:value="addgroup.describe" placeholder="请输入描述" allow-clear />
				</a-form-item>
			</a-form>
		</a-modal>
		<a-modal v-model:visible="addVisibleModel" title="编辑职级通道" @ok="handleAddChannel"
			@cancel="addformChaeenl={};addVisibleModel=false" :label-col="labelCol" :wrapper-col="wrapperCol">
			<a-form :model='addformChaeenl'>
				<a-form-item class='mb10' :labelCol='{span:6}' :wrapperCol="{span:15}" name="name"
					:rules="[{ required: true, message: '通道不能为空',tagger:'blur' }]" label="职级通道名称">
					<a-input placeholder="请输入" v-model:value="addformChaeenl.name"></a-input>
				</a-form-item>
				<a-form-item class='mb10' :labelCol='{span:6}' :wrapperCol="{span:15}" name="lead" label="职级分组">
					<a-switch unCheckedChildren='关' checked-children="开" un-checked-children="0" checkedValue='1'
						v-model:checked="addformChaeenl.is_group" />
				</a-form-item>
			</a-form>
		</a-modal>
		<!-- 通道 -->
		<a-modal :footer='null' v-model:visible="sortModel" title="管理职级通道" width="70%" @ok="handleOksort">
			<div class="FieldSettingsBox">
				<div class="FieldSettingsBoxTitle FieldSettingsBoxHeader">
					<span>
						&nbsp; &nbsp;
					</span>
					<span>职级通道名称</span>
					<span>职级分组</span>
					<span>职级数</span>
					<span style="color: #666;">操作</span>
				</div>

				<!-- 	<draggable v-model="all_list" chosenClass="chosen" forceFallback="true" group="people" animation="1000"
					@start="onStart" @unchoose='unchoose' @end="onEnd">
					<transition-group> -->
				<div draggable="true" @dragstart="dragstart($event, -1, index)" @dragover="dragover"
					@dragenter="dragenter($event, -1, index)" @dragend="dragend($event, -1, index)" @drop="drop"
					class="FieldSettingsBoxTitle" v-for="(element,index) in all_list" :key="element.id">
					<span>
						<BarsOutlined></BarsOutlined>
					</span>
					<span>
						{{element.name}}
					</span>
					<span>
						{{element.is_group==1?'已启用':'未启用'}}
					</span>
					<span>
						{{element.rank_num}}
					</span>
					<span>
						<span class="cq_color " style='width:100px' @click.stop='edit_channel(element)'> 编辑</span>
						<a-popconfirm v-if='element.rank_num<=0' title="是否确认删除?"
							@confirm.stop="delChannelOk(element.id)" @cancel="cancel">
							<span class="cq_error">删除</span>
						</a-popconfirm>
						<span href="#" v-else class="cq_error" style='color:#999;'>删除</span>
					</span>
				</div>
				<!-- </transition-group>
				</draggable> -->
			</div>
			<div class="dashedBtn">
				<a-button class='pointerCancel' type="dashed" @click='addVisibleModel=true'> + 添加职级通道</a-button>
			</div>
		</a-modal>

	</div>
</template>

<script>
	import {
		rankChannelGroup,
		rankList,
		modifyRank,
		modifyRankGroup,
		batchAddRank,
		removeRank,
		rankChannel,
		rankChannelSort,
		modifyRankChannel,
		removeRankChannel
	} from '@/api/organization.js';
	import advanced from './compent/advanced.vue'
	import strengthSet from './compent/strengthSet.vue'
	import FilterForm from '@/components/form/FilterForm'
	import {
		Modal
	} from 'ant-design-vue';
	// import {
	// 	VueDraggableNext
	// } from 'vue-draggable-next'
	export default {
		components: {
			advanced,
			FilterForm,
			// draggable: VueDraggableNext,
		},
		data() {
			return {
				is_group:0,
				visiblebatch: false,
				use_level: [],
				group_list: [],
				addgroup: {},
				where: {},
				addGroupModel: false,
				addVisibleModel: false,
				sortModel: false,
				all_list: [],
				choose: -1,
				labelCol: {
					style: {
						width: '100px'
					}
				},
				wrapperCol: {
					span: 14
				},
				visit: true,
				searchName: '', //搜索组织名称/组织代码
				columns: [{
						title: '职级编号',
						dataIndex: 'code',
					},
					{
						title: '职级名称',
						dataIndex: 'name',
						key: 'name',

					},
					{
						title: '关联职等',
						dataIndex: 'level',
						key: 'level',
					},
					{
						title: '在职员工',
						dataIndex: 'user_num',
						key: 'user_num',
					},
					{
						title: '相关岗位数',
						dataIndex: 'role_num',
						key: 'role_num',
					},
					{
						title: '描述',
						dataIndex: 'describe',
						key: 'describe',
					},
					{
						title: '操作',
						dataIndex: 'op',
						scopedSlots: {
							customRender: 'op'
						},
					},


				],
				rank_list: [],
				visibleDrawer: false, //控制新增组织抽屉
				typeArr: [{
					label: '公司',
					value: 1
				}, {
					label: '部门',
					value: 2
				}], //组织类型
				stop_approval_index: null,
				addformChaeenl: {},
				addformState: {
					channel_id: '',
					code: '',
					level: '',
					name: '',
					describe: '',
					group_id: ''
				}, //新增组织表单
				batchinfo: {
					level: []
				},
				levelList: [],
				active_channel_info: {},
				setUpVisibleModel: false, //设置企业负责人或者分管领导弹框
				setUpVisibleModelType: 1,
				person_name: '', //设置企业负责人或者分管领导的值
				visibleDrawerStrength: false, //人员编制抽屉
				strengthInfo: '', //人员编制详情
				rank_channel_list: [],
				active_channel: 0,
			}
		},
		created() {
			this.get_rank_channel()
		},
		methods: {
			changechannel(){
				this.rank_channel_list.forEach(item=>{
					if(item.id ==this.addformState.channel_id ){
						this.is_group = item.is_group
					}
				})
				rankChannelGroup({
					data: {
						channel_id:this.addformState.channel_id,
					}
				}).then(res => {
					this.group_list = res.data.group
					this.use_level = res.data.use_level.split(',')
				})
			},
			dragstart(e, ind) {
				// e.preventDefault()
			},
			drop(e) {
				e.preventDefault()
			},
			// 拖拽位置
			dragenter(e, ind, index) {
				e.preventDefault()
				if (ind != -1) {
					this.stop_index = ind;
				}
				this.stop_approval_index = index
			},
			dragover(e) {
				e.preventDefault()
			},
			// 拖拽完成
			dragend(e, ind, index) {
				let list = this.all_list;
				let move_data = list[index];
				list.splice(index, 1)
				list.splice(this.stop_approval_index, 0, move_data)
				this.all_list = list;
				this.onEnd()
			},
			unchoose() {
				return false
			},
			// 导出
			down_link() {
				this.$method.handleExport({}, '/manage/organize.export/exportRank')
			},
			batchClose() {
				this.visiblebatch = false
				this.batchinfo = {
					level: []
				}
			},
			add_batch() {
				batchAddRank({
					data: {
						...this.batchinfo,
						level: this.levelList.join(',')
					}
				}).then(res => {
					// this.get_rank_channel()
					this.get_rank_List()
					this.get_group_list()
					
					this.batchClose()
					 this.levelList=[]
					 this.batchinfo.level = []
				})
			},
			choose_level2(item) {
				if(this.batchinfo.level.indexOf(item)>-1){
					return false;
				}
				if(this.levelList.indexOf(item)>-1){
					let levelIndex = this.levelList.indexOf(item)
					this.levelList.splice(levelIndex,1)
				}else{
					this.levelList.push(item)
				}
				
				
			
				// 	let levelIndex = this.batchinfo.level.indexOf(item)
				// 	this.batchinfo.level.splice(levelIndex,1)
				// }else{
				// 	this.batchinfo.level.push(item)
			},
			setbatch(item) {
				this.levelList = []
				this.batchinfo = {
					name: item.name,
					code: item.code,
					group_id: item.id,
					level: []
				}
				item.level_select.forEach(item => {
					if (101 > item > 0) {
						this.batchinfo.level.push(item)
					}
					if (this.use_level.indexOf(String(item)) > -1) {
						this.use_level.splice(this.use_level.indexOf(String(item)))
					}
				})
				this.visiblebatch = true
			},
			choose_level(item) {
				if (this.use_level.indexOf(String(item)) > -1) {
					return false
				}else{
					
					this.addformState.level = item
				}
			},
			add_rank() {
				if(this.addformState.level==-1){
					this.addformState.level=''
				}
				modifyRank({
					data: {
						...this.addformState
					}
				}).then(res => {
					this.addformState = {
						channel_id:this.active_channel,
						level:-1
					}
					this.visibleDrawer = false
					this.get_rank_List()
					this.get_group_list()
					
				})
			},
			get_group_list() {
				rankChannelGroup({
					data: {
						channel_id: this.active_channel,
					}
				}).then(res => {
					this.group_list = res.data.group
					this.use_level = res.data.use_level.split(',')
				})
			},
			cancel() {},
			cancelGroup() {
				this.addgroup = {}
				this.addGroupModel = false
			},
			handleOkgroup() {
				modifyRankGroup({
					data: {
						channel_id: this.active_channel,
						...this.addgroup
					},
					info: true
				}).then(res => {
					this.cancelGroup()
					this.get_rank_List()
					this.get_group_list()
				})
			},
			get_rank_List() {
				rankList({
					data: {
						...this.where
					}
				}).then(res => {
					this.rank_list = res.data.list
				})
			},
			edit_channel(item) {
				this.addformChaeenl = item
				this.addVisibleModel = true
			},
			derankOk(id) {
				let that = this
				Modal.confirm({
					title: '提示',
					content: '是否确认删除该数据?',
					onOk() {
						removeRank({
							data: {
								id: id
							},
							info: true
						}).then(res => {
							that.get_rank_List()
							that.get_group_list()
						})
					},
					onCancel() {

					},
				})

			},
			delChannelOk(id) {
				removeRankChannel({
					data: {
						id: id
					},
					info: true
				}).then(res => {
					this.get_rank_channel()
				})
			},
			handleAddChannel() {
				modifyRankChannel({
					data: {
						...this.addformChaeenl,
					},
					info: true
				}).then(res => {
					this.get_rank_channel()
					this.addVisibleModel = false
					this.addformChaeenl = {}
				})
			},
			choose_channel(item) {
				
				this.active_channel_info = item
				this.active_channel = item.id
				this.where.channel_id = item.id
				this.addformState.channel_id = item.id
				this.get_rank_List()
				this.get_group_list()
			},
			get_rank_channel() {
				rankChannel().then(res => {
					this.all_list = res.data.list
					this.all_list_temp = JSON.parse(JSON.stringify(res.data.list))
					this.rank_channel_list = res.data.list
					this.active_channel = res.data.list[0].id
					this.where.channel_id = res.data.list[0].id
					this.addformState.channel_id = res.data.list[0].id
					this.get_rank_List()
					this.get_group_list()
				})
			},
			onStart() {
				this.drag = true;
			},
			onEnd() {

				this.drag = false;
				let sort = []
				if (JSON.stringify(this.all_list_temp) == JSON.stringify(this.all_list)) {
					return false
				}
				this.all_list.forEach(item => {
					sort.push({
						id: item.id
					})
				})

				rankChannelSort({
					data: {
						data: sort
					},
					info: true
				}).then(res => {
					this.get_rank_channel()
				})
			},

			//搜索组件返回
			filterSearch(e) {
				this.popoverVisible = false
			},
			// 新增下级
			addSubordinate(record) {

				this.visibleDrawer = true
			},
			//编制下级
			setSubordinate(record) {
				if (record.is_group == 1) {
					// 分组
					this.addGroupModel = true
					this.addgroup = JSON.parse(JSON.stringify(record))
				} else {
					this.visibleDrawer = true
					this.addformState = record
					this.addformState.channel_id = this.active_channel
					this.addformState.group_id = record.pid
					if (this.use_level.indexOf(String(record.level)) > -1) {
						this.use_level.splice(this.use_level.indexOf(String(record.level)))
					}

				}
			},
			//新增组织关闭的回调
			addOnClose() {
				this.visibleDrawer = false
				this.addformState = {
					level:-1,
					channel_id:this.active_channel,
					
				}
			},

			handleOksort() {
				let sort = []
				this.all_list.forEach(item => {
					sort.push(item.id)
				})
				rankChannelSort({
					data: {
						sort: sort.join(',')
					},
					info: true
				}).then(res => {

					this.$router.back()
				})

				this.sortModel = false
			},

			//批量删除组织
			delOrganization() {
				this.$router.push('/organization/delOrgan')
			},
			//历史组织
			historyOrganization() {
				this.$router.push('/organization/historyOrgan')
			},
			//调整排序
			sortOrganization() {
				this.$router.push('/organization/sortOrgan')
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	@import url("../../assets/less/app.less");
	@import 'info.less';

	.enterprise {
		background-color: #fff;
	}

	.organizationBox {
		// margin: 12px;
		padding: 16px 24px;
		background-color: #fff;

		.organizationHeader {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.o_h_left {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.o_h_l_right {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 20px;

				}
			}

			.o_h_right {
				.ant-btn {
					margin: 0 10px;
				}
			}
		}
	}

	.organizationContain {
		margin-top: 30px;

		/deep/(.ant-table-row td:first-child) {
			display: flex;
			align-items: center;
		}

		.o_g_a_c_name {
			display: inline-block;
			cursor: pointer;

			.anticon-book {
				margin-right: 5px;
			}

			.o_g_a_c_name_box {
				display: flex;
				align-items: center;

				.nameBTex:hover {
					color: #397dee;
				}

				.operateBox {
					min-width: 180px;

					.operate {
						color: #397dee;
						font-size: 12px;
						margin: 0 10px;
						display: none;

					}

					.operate:hover {
						color: #7aadff;
					}
				}

				.operateBoxCopy {
					display: flex;

					.operate {
						margin: 0 10px;
					}
				}

			}

		}

		.o_g_a_c_name:hover .operateBox .operate {
			display: inline-block;
		}

		.commonBox {
			display: flex;

			.anticon-form {
				margin-left: 10px;
				display: none;
				cursor: pointer;
				color: #397dee;
			}
		}

		.commonBox:hover .anticon-form {
			display: inline-block;
		}
	}

	/deep/.addDrawerFormBox {
		display: none;
	}

	.o_g_a_c_name_box {
		.operateBox {
			min-width: 180px;

			.operate {
				color: #397dee;
				font-size: 12px;
				margin: 0 10px;
				display: none;

			}

			.operate:hover {
				color: #7aadff;
			}
		}
	}

	.job_lv_qQOHK {
		position: relative
	}

	.job_lv_qQOHK .boxs_3Z7My {
		position: relative;
		width: 500px;
		height: 180px;
		padding: 4px;
		overflow-y: auto;
		border: 1px solid #e1e6eb
	}

	.job_lv_qQOHK .boxs_3Z7My span {
		display: inline-block;
		width: 45.33333px;
		height: 34px;
		line-height: 34px;
		text-align: center;
		border: 1px solid #f0f4f8;
		cursor: pointer
	}

	.job_lv_qQOHK .boxs_3Z7My span:hover {
		color: @cq_color;
		color: @cq_color;
		border-color: @cq_color;
		border-color: @cq_color;
	}

	.job_lv_qQOHK .boxs_3Z7My span.disabled_1wLWs {
		color: #bbbec4;
		background: #f8f8f9;
		border-color: #f0f4f8 !important;
		cursor: not-allowed
	}

	.job_lv_qQOHK .boxs_3Z7My span.group_2112- {
		color: @cq_color;
		color: @cq_color;
		background: @cq_color;
		background: @cq_color;
		cursor: not-allowed
	}

	.job_lv_qQOHK .boxs_3Z7My span.selected_3r1hy {
		color: #fff;
		background: @cq_color;
		background: @cq_color;
		border-color: @cq_color;
		border-color: @cq_color;
	}

	.job_lv_qQOHK .boxs_3Z7My span.selected_4r1hy {
		color: #fff;
		background: @cq_color;
		opacity: 0.8;
	}

	.job_lv_qQOHK .msg_33-HC {
		position: absolute;
		bottom: -16px;
		left: 0;
		padding-top: 4px;
		color: #80848f;
		font-size: 12px;
		line-height: 1
	}

	.passages_170xn {
		// margin-bottom: 16px
	}

	.passages_170xn .passage_19z-n {
		display: inline-block;
		margin: 0 40px 16px 0;
		font-weight: 700;
		font-size: 16px;
		cursor: pointer
	}

	.passages_170xn .passage_19z-n.active_1NcT- {
		color: @cq_color;
		color: @cq_color;
	}

	.passages_170xn .settings_1_vPz {
		display: inline-flex;
		align-items: center;
		margin-bottom: 16px;
		color: #80848f;
		font-size: 12px;
		vertical-align: bottom;
		cursor: pointer;

		span:nth-child(2) {
			margin: 0 4px;
		}
	}

	.passages_170xn .settings_1_vPz:hover,
	.passages_170xn .settings_1_vPz:hover svg {
		color: @cq_color;
	}

	.FieldSettingsBox {
		border: 1px solid @cq_line_e;
		margin-top: 12px;
	}

	.FieldSettingsBoxTitle {
		display: flex;
		width: 100%;
		padding: 8px 12px;
		border-bottom: 1px solid @cq_line_e;

		span:nth-child(2) {
			flex: 2;
			margin-left: 8px;
		}

		span:nth-child(3) {
			flex: 3;
		}

		span:nth-child(4) {
			// color: #fff;
			flex: 1;

		}

		span:nth-child(5) {
			color: #fff;
			width: 120px;
			text-align: center;

		}
	}

	.FieldSettingsBoxHeader {
		background: #f5f8fa;
	}

	.FieldSettingsBoxTitle:hover {
		background: #f5f8fa;

		span:nth-child(5) {
			color: #666;
		}

	}

	.dashedBtn {
		margin-top: 20px;
	}

	.op {
		.cq_color {
			color: transparent;
		}

		.cq_error {
			color: transparent;
		}

		/deep/.ant-divider {
			background: transparent;
		}

	}

	// .op:hover{
	// 	.cq_color{
	// 		color:@cq_color;
	// 	}
	// 	.cq_error{
	// 		color:@cq_error;
	// 	}
	// 	/deep/.ant-divider{
	// 		background:#f0f0f0;
	// 	}
	// }
	.tips_titele_box {
		margin-top: 0px;
	}

	/deep/.ant-table-tbody>tr:hover {
		.cq_color {
			color: @cq_color;
		}

		.cq_error {
			color: @cq_error;
		}

		/deep/.ant-divider {
			background: #f0f0f0;
		}
	}

	.mb10 {
		margin-bottom: 10px
	}

	.uploadLine {
		padding: 6px 12px;
		display: inline-block;
	}
	.line{
		margin:0 14px;
		display: inline-block;
	}
	.tips_top_box{
		.close_open{
			color:#fff;
		}
	}
	.tips_top_box:hover {
		.close_open{
			color:@cq_color;
		}
	}
	.tips_titele_box{
		margin-top:0px;
	}
</style>