<template>
    <div>
        <a-modal :zIndex="10001" v-model:visible="visibleAdvanced" :title="type==1?'选择部门':'选择员工'" @ok="advanceModal" @cancel="cancelAdvance">
            <div class="advancedBox" v-if="type == 1">
                <a-input placeholder="搜索组织名称">
                    <template #prefix>
                        <!-- <SearchOutlined /> -->
                    </template>
                </a-input>
                <a-radio-group v-model:value="radioValue" name="radioGroup">
                    <a-tree @select="checkedChange" :tree-data="gData" :defaultExpandAll="gData.length > 0 ? true : false">
                        <template #title="record">
                            <a-radio :value="record.key">{{ record.title }}</a-radio>
                        </template>
                    </a-tree>
                </a-radio-group>
            </div>
            <div v-else class="advancedBox">
                <a-input placeholder="搜索人员或部门">
                    <template #prefix>
                        <SearchOutlined />
                    </template>
                </a-input>
                <div class="staffAdvancedBox">
                    <div class="staffBox_header">
                        <span>江西大地医药保健品有限公司江西分公司</span>
                    </div>
                    <div class="scollStaff">
                        <div class="staffBox_contain">
                            <div class="staffBox_contain_box" v-for="(item, index) in departmentArr" :key="index">
                                <folder-open-two-tone />
                                <span>{{ item.title }}</span>
                                <span class="staffBox_span">（{{ departmentArr.length }}个）</span>
                            </div>
                        </div>
                        <div class="staffBox_contain_checked">
                            <a-radio-group v-model:value="staffRadiovalue" name="radioGroup" @change="changeClikc">
                                <a-radio v-for="(item, index) in staffArr" :key="index" :value="item.value">
                                    <div class="s_b_c_c_box">
                                        <img :src="item.img" alt="">
                                        <span>{{ item.username }}</span>
                                        <span class="staff_c_c_text">{{ item.time }}</span>
                                    </div>
                                </a-radio>
                            </a-radio-group>

                        </div>
                    </div>
                </div>
            </div>
        </a-modal>
    </div>
</template>
    
<script>

export default {
    components: { },
    props: {
        visibleAdvanced: {
            type: Boolean,
            default: true
        },
        type: {
            type: Number,
            default: 2
        }
    },
    data() {
        return {
            gData: [
                {
                    title: '江西长勤企业有限公司',
                    key: '0-0',
                    children: [
                        {
                            title: '技术部',
                            key: '0-0-0',
                            children: [
                                {
                                    title: '张三',
                                    key: '0-0-0-0',
                                },
                                {
                                    title: '李四',
                                    key: '0-0-0-1',
                                },
                            ],
                        },
                        {
                            title: '运营部',
                            key: '0-0-1',
                            children: [
                                {
                                    key: '0-0-1-0',
                                    title: '李伟',
                                    id: 12
                                },
                            ],
                        },
                    ],
                },
            ],
            radioValue: '',//单选框选择
            info: '',//选中节点详情
            departmentArr: [
                {
                    title: '技术部',
                    value: 1,
                }
            ],//部门数组
            staffArr: [{
                username: '张三',
                value: 1,
                img: '../../../image/alipay_s.png',
                time: '2022-10-1'
            }],//员工列表
            staffRadiovalue: '',//员工单选
        }
    },
    methods: {
        // 点击节点
        checkedChange(e, ee) {
            console.log(e)
            console.log(ee)
            this.radioValue = e[0]
            this.info = ee.selectedNodes[0].props.dataRef
        },
        advanceModal() {
            this.$emit('advanceModal', this.info)
            this.radioValue = ''
            this.info = ''
            this.staffRadiovalue = ''
        },
        cancelAdvance() {
            this.$emit('cancelAdvance')
            this.radioValue = ''
            this.info = ''
            this.staffRadiovalue = ''
        },
        changeClikc(e){
            console.log(e.target.value)
            this.staffArr.forEach(item=>{
                if(item.value = e.target.value){
                    this.info = item
                    return 
                }
            })

        }
    }
}
</script>
    
<style lang="less" scoped="scoped">
/deep/.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #fff;
}

.staffAdvancedBox {
    margin-top: 10px;

    .staffBox_header {}

    .staffBox_contain_box {
        display: flex;
        align-items: center;
        margin: 10px 0;
        height: 50px;
        padding: 0 10px;
        cursor: pointer;

        .anticon-folder-open {
            font-size: 30px;
            margin-right: 10px;
        }

        .staffBox_span {
            font-size: 12px;
            color: #80848f;
        }
    }

    .scollStaff {
        height: 360px;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
    }

    .staffBox_contain_checked {
        padding: 0 10px;
        display: flex;
        align-items: center;
        height: 50px;

        /deep/.ant-radio-wrapper {
            display: flex;
            align-items: center;
        }

        .s_b_c_c_box {
            display: flex;
            align-items: center;
            width: 400px;
            position: relative;

            span {
                color: #397dee;
            }
        }

        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 10px;
        }

        .staff_c_c_text {
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .staffBox_contain_checked:hover {
        background-color: #f2f2f2;
    }

    .staffBox_contain_box:hover {
        background-color: #f2f2f2;
    }

}</style>