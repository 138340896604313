<template>
    <div class="strengthDrawerBox">
        <div class="s_d_b_header">
            <div class="s_d_b_h_h">
                <div class="s_d_b_h_h_left">
                    当前编制人数：<span class="persons">{{strengthInfo}}</span>人<span class="personsNoe">（缺编4）</span>
                </div>
                <div class="s_d_b_h_h_right">
                    <a-button type="primary" @click="headcount = true">调整编制</a-button>
                </div>
            </div>
            <div class="s_d_b_h_f" v-if="headcount">
                <div class="s_d_b_h_f_left">调整编制</div>
                <div class="s_d_b_h_f_contain">
                    <a-input type="number" :disabled="info.checked" v-model:value="info.number" />
                    <a-checkbox v-model:checked="info.checked" @change="notHeadcountChange"
                        class="notStrengthS">不限编制</a-checkbox>
                </div>
                <div class="s_d_b_h_f_right">
                    <a-button @click="answer"><template #icon><check-outlined /></template></a-button>
                    <a-button @click="wrong"><template #icon><close-outlined /></template></a-button>
                </div>
            </div>
        </div>
        <div class="s_d_b_department">
            <div class="s_d_b_d_header">
                部门人员概况
            </div>
            <div class="s_d_b_d_contain">
                <span>在职：1人</span>
                <span>全职：2人</span>
                <span>离职中：2人</span>
                <span>待入职：2人</span>
            </div>
        </div>
<!--        <div class="s_d_b_department">
            <div class="s_d_b_d_header">
                设置部门内岗位编制
                <span class="s_d_b_d_h_add" @click="addStrengthPost">添加岗位编制</span>
            </div>
            <div class="s_d_b_d_d_contain">
                <div class="s_d_b_d_c_boxdepartment" v-for="(item,index) in postArr" :key="index">
                    <div class="s_d_b_d_c_b_left">
                        <span class="seaveLefsf">{{ item.post }}</span>
                        <span class="seaveLef">在岗{{item.onDuty}}人 缺编{{item.notDuty}}人</span>
                    </div>
                    <div class="s_d_b_d_c_b_contain">
                        编制{{item.strength}}人
                    </div>
                    <div class="s_d_b_d_c_b_right" @click="setStrength(item)">
                        调整编制
                    </div>
                </div>
            </div>
            <div class="s_d_b_department_footer">
                <span class="s_d_b_department_footer_sum">合计：</span>
                <span>在岗人数1人</span>
                <span>岗位编制人数33人</span>
            </div>
        </div> -->
 <!--       <a-modal :zIndex="1001"  v-model:visible="visibleAdd" :title="type == 1? '添加岗位编制':'调整岗位编制'" @ok="modelStrengChange">
            <a-form ref="addPostForm" style="margin:0 40px;display: flex;align-items: flex-end;justify-content: center;flex-direction: column;" :model="addInfo">
                <a-form-item v-if="type == 1" name="post" label="请选择岗位" :rules="[{ required: true, message: '请选择岗位' }]" >
                    <a-select style="width: 245px;" :options="leadArr"
                        v-model:value="addInfo.post" placeholder="请选择" allowClear></a-select>
                    <a-button @click.stop="tooplipClick"><bars-outlined /></a-button>
                </a-form-item>
                <a-form-item v-else name="post" label="岗位"  allowClear>
                    <a-input style="width: 290px;" v-model:value="addInfo.post" disabled></a-input>
                </a-form-item>
                <a-form-item name="personnel" label="人员编制">
                    <a-input style="width: 290px;" v-model:value="addInfo.personnel" type="number"></a-input>
                </a-form-item>
            </a-form>
        </a-modal> -->
        <!-- 高级选择 -->
      <!--  <strengthModel @strengthchange="strengthchange" @cancelstrength="cancelstrength"
            :visibleAdvanced="visibleAdvanced" /> -->
    </div>
</template>
    
<script>
import strengthModel from './strengthModel.vue'

export default {
    components: {
    },
	props: {
	    visibleAdvanced: {
	        type: Boolean,
	        default: true
	    },
	    strengthInfo: {
	        type: Number,
	        default: 0
	    }
	},
    data() {
        return {
            headcount: false,//调整编制
            info: {
                number: 0,
                checked: false
            },
            visibleAdd:false,//添加编制弹框
            addInfo:{
                post:'',
                personnel:0
            },//添加岗位编制
            leadArr:[],//添加岗位的数组
            visibleAdvanced:false,//高级选择
            advancedType:1,
            visibleAdvanced:false,
            type:1,
            postArr:[
                {
                    post:'组长',
                    onDuty:1,
                    notDuty:2,
                    strength:12,
                    personnel:15
                }
            ],//岗位数组
        }
    },
    methods: {
        notHeadcountChange(e) {
            console.log(e)
            this.info.checked = e.target.checked
        },
        answer() {
            this.headcount = false
			if(this.info.checked){
				this.info.number = 0
			}
			this.$emit('answer',this.info.number)
        },
        wrong() {
            this.headcount = false
        },
        //添加编制岗位
        addStrengthPost() {
            this.visibleAdd = true
            this.type = 1
        },
        // 点击获取更多
        tooplipClick(){
            this.visibleAdvanced = true
        },
        strengthchange(e){
            this.info.post = e.title
            this.visibleAdvanced = false
        },
        cancelstrength(){
            this.visibleAdvanced = false
        },
        //添加岗位编制确认
        modelStrengChange(){
            this.visibleAdd = false
            if(this.type == 1){
                this.postArr.push(this.addInfo)
            }else{ // 修改

            }
            this.$refs.addPostForm.resetFields()
        },
        // 修改编制
        setStrength(item){
            console.log(item)
            this.visibleAdd = true
            this.type = 2
            this.addInfo = {
                post: item.post,
                personnel: item.personnel
            }

        }
    }
}
</script>
    
<style lang="less" scoped="scoped">
.strengthDrawerBox {
    padding: 20px;

    .s_d_b_header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        .s_d_b_h_h {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .s_d_b_h_h_left {
                font-size: 20px;

                .persons {
                    color: #f90;
                }

                .personsNoe {
                    font-size: 12px;
                    color: #80848f;
                }
            }
        }

        .s_d_b_h_f {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .s_d_b_h_f_contain {
                position: relative;

                .notStrengthS {
                    position: absolute;
                    bottom: -30px;
                    left: 0;
                }
            }

            .s_d_b_h_f_right {
                display: flex;
                align-items: center;

                .ant-btn {
                    width: 25px;
                    height: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 5px;

                    .anticon {
                        font-size: 12px;
                        color: #397dee;
                    }
                }
            }

        }
    }

    .s_d_b_department {
        margin-top: 50px;

        .s_d_b_d_header {
            border-bottom: 1px solid #f0f4f8;
            font-size: 16px;
            font-weight: 700;
            padding: 10px 0;
            margin-bottom: 24px;

            .s_d_b_d_h_add {
                float: right;
                font-size: 14px;
                font-weight: 400;
                color: #397dee;
                cursor: pointer;
            }
        }

        .s_d_b_d_contain {
            display: flex;
            align-items: center;
            flex-flow: wrap row;

            span {
                width: 50%;
                margin-bottom: 8px;
            }
        }

        .s_d_b_d_d_contain {
            .s_d_b_d_c_boxdepartment {
                box-shadow: 0 4px 15px rgb(210 214 221);
                height: 60px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px;
                margin: 10px 0;
                .s_d_b_d_c_b_left {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;

                    .seaveLefsf {
                        font-weight: 700;
                    }

                    .seaveLef {
                        font-size: 12px;
                    }
                }

                .s_d_b_d_c_b_right {
                    color: #397dee;
                    cursor: pointer;
                }
            }
        }

        .s_d_b_department_footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 20px;

            span {
                margin: 0 10px;
            }

            .s_d_b_department_footer_sum {
                font-weight: 700;
            }
        }
    }
}</style>