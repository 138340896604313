<template>
    <div class="form">
        <div v-for="item in form" :key="item" class="sub">
            <span class="label" v-if="item.label">{{ item.label }}</span>
            <template v-if="item.type === 'input'">
                <a-input v-model:value="formState[item.name]" :style="{ width: item.width ? item.width : '220px' }"
                    :placeholder="item.placeholder ? item.placeholder : '请输入'"></a-input>
            </template>
            <template v-else-if="item.type === 'select'">
                <a-select v-model:value="formState[item.name]" :style="{ width: item.width ? item.width : '220px' }"
                    :placeholder="item.placeholder ? item.placeholder : '请选择'" :options="item.list" :allowClear="true" @change="selectChange($event,item.name)"></a-select>
            </template>
            <template v-else-if="item.type === 'input_select'">
                <a-select v-model:value="formState[item.name]" show-search
                    :placeholder="item.placeholder ? item.placeholder : '请选择'"
                    :style="{ width: item.width ? item.width : '220px' }" :default-active-first-option="false"
                    :show-arrow="false" :filter-option="false" :not-found-content="null" :options="item.options"
                    @search="handleSearch($event, item)" @change="handleChange($event, item)">
                </a-select>
            </template>
            <template v-else-if="item.type === 'rangePicker'">
                <a-range-picker v-model:value="formState[item.name]" :style="{ width: item.width ? item.width : '220px' }"
                    ></a-range-picker>
            </template>
            <template v-else-if="item.type === 'month'">
                <a-month-picker v-model:value="formState[item.name]" format="YYYY-MM" valueFormat="YYYY-MM" :style="{ width: item.width ? item.width : '220px' }"
                    ></a-month-picker>
            </template>
            <template v-else-if="item.type === 'date'">
                <a-date-picker v-model:value="formState[item.name]" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" :style="{ width: item.width ? item.width : '220px' }"
                    ></a-date-picker>
            </template>
    
            <!-- <template v-else-if="item.type === 'multiple'">
                <MultipleCountSelect width="220px" :formKey="item.name" :selectValue="formState[item.name]"
                    :optionProps="item.list" @change="onChangeMultipleSelect" />
            </template> -->
            <template v-else-if="item.type == 'tree'">
				<a-tree-select treeNodeFilterProp="name" show-search :tree-data="item.list" :style="{ width: item.width ? item.width : '220px' }"
					v-model:value="formState[item.name]" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
					:placeholder="item.placeholder ? item.placeholder : '请选择' + item.label" allow-clear treeDefaultExpandAll
					 />
			</template>
        </div>
        <div class="sub">
            <a-button type="primary" @click="onSearch">查询</a-button>
            <a-button style="margin-left: 15px" @click="resetClick">重置</a-button>
        </div>
</div>
</template>

<script>
// import MultipleCountSelect from '@/modules/approval/views/components/MultipleCountSelect'
export default {
    components: {
        // MultipleCountSelect
    },
    props: {
        form: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            formState: {},
            timeout: undefined,
        }
    },
    watch: {
        form: {
            handler(newValue, oldValue) {
                this.handle_default_value();
            },
            deep: false,
            immdiate: true
        }
    },
    created() {
        this.handle_default_value(true);
    },
    methods: {
        onSearch() {
            this.$emit('search', this.formState);
        },
        resetClick() {
            console.log(12)
            this.handle_default_value();
            this.$emit('search', this.formState);
        },
        handle_default_value(isFirst) {
            for(const item of this.form) {
                if(item.default) {
                    this.formState[item.name] = item.default;
                }else {
                    this.formState[item.name] = undefined;
                }
                if(item.first && isFirst) {
                    this.formState[item.name] = item.first;
                }
            }
        },
        onChangeMultipleSelect(value, key) {
            this.formState[key] = value;
            console.log(this.formState)
        },
        handleChange(val, item) {
            console.log(val, 'change', item);
        },
        handleSearch(val, item) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            
            this.timeout = setTimeout(() => {
                item.api({
                    data: {
                        keyword: val
                    }
                }).then(res => {
                    const list = res.list;
                    if(!val) {
                        list.unshift({value: -1, label: '所有帐号'});
                    }
                    item.options = list;
                })
            }, 500);
            // console.log(val, 'search', item);
        },
        // 选择框选中
        selectChange(e,name){
            let obj = {
                item : e,
                name
            }
            if(name == 'probation_period'){
                delete this.formState['probation']
            }
            this.$emit('selectChange',obj)
        },
        // 表单赋值
        formAssignment(field,value){
            console.log(field)
            this.$nextTick(()=>{
                this.formState[field] = value * 1
            })
            this.$emit('selectChange',obj)
        }
    }
}
</script>

<style lang="less" scoped>
    .form {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 12px 0;
        .sub {
            display: flex;
            align-items: center;
            margin: 0 20px 15px 0;
            .label {
                width: 60px;
                text-align-last: justify;
                text-align: right;
                margin-right: 10px;
                white-space: pre-wrap;
            }
        }
    }

</style>